<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Başlık"
            label-for="title"
          >
            <validation-provider
              #default="{ errors }"
              name="Başlık"
              rules="required"
            >
              <b-form-input
                id="title"
                v-model="itemData.title"
                placeholder="Başlık"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Üst Kategori"
            label-for="id_service_categories"
          >
            <v-select
              id="id_service_categories"
              v-model="itemData.id_service_categories"
              :options="dataList"
              label="title"
              :reduce="item => item.id"
              placeholder="Ana Kategori"
            >
              <template v-slot:option="option">
                <div>
                  {{ option.title }}
                </div>
                <div>
                  <small class="text-warning">{{ option.main_category }}</small>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Sıra No"
            label-for="ordering"
          >
            <validation-provider
              #default="{ errors }"
              name="Sıra No"
              rules="required"
            >
              <b-form-input
                id="ordering"
                v-model="itemData.ordering"
                placeholder="Sıra No"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <content-text />
        </b-col>
      </b-row>
    </b-card>
    <image-card />
    <b-button
      variant="primary"
      :disabled="itemData.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCol, BFormGroup, BFormInput, BRow, BButton,
} from 'bootstrap-vue'

import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import ContentText from '@/views/Admin/Service_categories/elements/Content.vue'
import ImageCard from '@/views/Admin/Service_categories/elements/Image.vue'

export default {
  name: 'ItemForm',
  components: {
    vSelect,
    ValidationProvider,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    ContentText,
    ImageCard,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['serviceCategories/dataItem']
    },
    dataList() {
      return this.$store.getters['serviceCategories/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('serviceCategories/getDataList', {
        select: [
          'service_categories.id AS id',
          'service_categories.title AS title',
          'service_categories.content AS content',
          'service_categories.image AS image',
          'sc.title AS main_category',
        ],
        where: {
          'service_categories.id_service_categories': null,
        },
      })
    },
  },
}
</script>
